import React from "react"

import styles from './component.module.less'

export default function(props) {
  switch (props.tag) {
    case 'header':
      return (
          <header
              ref={props.reactRef}
              id={props.id}
              style={props.style}
              className={`${styles.contentContainer}${props.className ? ' ' + props.className : ''}`}
          >
            {props.children}
          </header>
      );
    case 'section':
      return (
          <section
              ref={props.reactRef}
              id={props.id}
              style={props.style}
              className={`${styles.contentContainer}${props.className ? ' ' + props.className : ''}`}
          >
            {props.children}
          </section>
      );
    default:
      return (
          <div
              ref={props.reactRef}
              id={props.id}
              style={props.style}
              className={`${styles.contentContainer}${props.className ? ' ' + props.className : ''}`}
          >
            {props.children}
          </div>
      );
  }
}
