import React from "react";
import {HelmetDatoCms} from "gatsby-source-datocms";

import CookieConsent from '../cookie-consent'

export default props => {
  const locale = props.locale || 'de';

  const seo = (props.seo || {tags: []});
  seo.tags = seo.tags.filter(s => {
    if (!s.attributes || !s.attributes.property) return true;
    if (props.robots && s.attributes && s.attributes.name === 'robots') return false;
    return s.attributes.property !== 'article:modified_time' && s.attributes.property !== 'article:published_time';
  });

  return (
      <>
        <CookieConsent locale={locale} visibilityRef={props.cookieConsentRef} />

        <HelmetDatoCms seo={seo}>
          <html lang={locale} />

          {props.title &&
              <title>{props.title}</title>
          }

          {props.robots &&
              <meta name="robots" content={props.robots} />
          }

          {(props.localeLinks || []).map((l, i) => (
              <link key={i} rel="alternate" hrefLang={l.locale} href={l.url} />
          ))}
        </HelmetDatoCms>
      </>
  );
}
