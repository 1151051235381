import React from "react"

import styles from './component.module.less'
import icons from '../../styles/_icons.module.less'

function toCamelCase(str){
  return str.split('-').map(function(word,index){
    // If it is the first word make sure to lowercase all the chars.
    if(index === 0){
      return word.toLowerCase();
    }
    // If it is not the first word only upper case the first char and lowercase the rest.
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join('');
}

export default function(props) {
  let theme;

  switch (props.theme) {
    case 'outline':
      theme = ' ' + styles.outline;
      break;
    case 'block':
      theme = ' ' + styles.block;
      break;
    case 'outline-rounded':
      theme = ' ' + styles.outline + ' ' + styles.rounded;
      break;
    case 'block-rounded':
      theme = ' ' + styles.block + ' ' + styles.rounded;
      break;
    default:
      theme = null;
  }

  if (props.href) {
    if (props.href.startsWith('https://apps.apple.com/')) {
      theme = styles.astore;
    }

    if (props.href.startsWith('https://play.google.com/')) {
      theme = styles.gstore;
    }
  }

  const dataAttributes = (btn) => {
    for (const prop in props) {
      if (btn && props.hasOwnProperty(prop) && prop.startsWith('data-')) {
        btn.setAttribute(prop, props[prop]);
      }
    }
  };

  let classNames = [styles.btn];

  if (theme) {
    classNames.push(theme);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  if (props.icon) {
    const iconClassName = toCamelCase(props.icon);
    if (icons[iconClassName]) {
      classNames.push(styles.icon);
      classNames.push(icons[iconClassName]);
    }
  }

  if (props.icon && icons[props.icon]) {
    classNames.push(icons[props.icon]);
  }

  classNames = classNames.join(' ');

  if (props.href) {
    return (
        <a href={props.href} className={classNames} target={props.target} rel={props.rel} ref={dataAttributes}>
          {props.children}
        </a>
    );
  }

  return (
      <button onClick={props.onClick} type={props.type || 'button'} className={classNames} ref={dataAttributes} disabled={props.disabled}>
        {props.children}
      </button>
  );
}
