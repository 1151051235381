import React from "react"

import styles from './component.module.less'

export default props => (
    <div className={`${styles.cbox}${props.className ? ' ' + props.className : ''}`}>
      <label className={styles.lbl}>
        <input
            onChange={props.onChange}
            onBlur={props.onBlur}
            id={props.id}
            name={props.name}
            type="checkbox"
            checked={props.checked}
            className={`${props.error ? styles.err : ''}`}
        />
        {props.children}
      </label>
      {props.error && <>{props.error}</>}
    </div>
);
