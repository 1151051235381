export default (paramName) => {

  const win = (typeof window !== 'undefined') ? window : {
    location: {
      search: ''
    }
  };

  const queryString = win.location.search.replace(/\?/, '');
  const queryParams = queryString.split('&').map(p => {
    const args = p.split('=');

    let value = '';
    if (args[1]) {
      value = decodeURIComponent(args[1].replace(/\+/, ' ').replace(/\+/g, ''));
    }

    return {
      name: args[0],
      value: value
    };
  });

  return queryParams.find(q => q.name === paramName) || null;
}
